<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.delete') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          {{ $t('msg.deleteItem', { n: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('nom_conditions_generals_cars:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="model.proveedor_id"
            :items="itemsProveedorForm"
            :search-input.sync="searchProveedorForm"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            @change="load()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="model.category_id"
            :items="categories"
            item-text="name"
            item-value="id"
            :label="$t('lbl.cat')"
            outlined
            dense
            hide-details
            @change="load()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="model.name"
            :label="$t('lbl.reference')"
            outlined
            dense
            hide-details
            @input="load()"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="model.proveedor_id || model.category_id || model.name"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="4"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.conditionGenerals') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.proveedors') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.cat') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.reference') }}
            </th>
            <th
              v-if="
                permisos.includes('nom_conditions_generals_cars:edit') ||
                  permisos.includes('nom_conditions_generals_cars:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              width="10%"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <!--{{
                iten.proveedor_id !== null
                  ? proveedores.filter(e => e.id === iten.proveedor_id).length > 0
                    ? proveedores.filter(e => e.id === iten.proveedor_id)[0].name_comercial
                      ? proveedores.filter(e => e.id === iten.proveedor_id)[0].name_comercial
                      : ''
                    : ''
                  : ''
              }}-->
              <span
                v-for="(prov, ind) in iten.proveedors"
                :key="ind"
              >
                {{ proveedores.filter(e => e.id === prov)[0].name_comercial }}
                <br />
              </span>
            </td>
            <td>{{ iten.category_id !== null ? iten.category.name : '' }}</td>
            <td>
              <v-tooltip
                v-if="iten.description"
                slot="append-outer"
                color="primary"
                top
                content-class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  <v-card
                    class="mx-auto"
                    max-width="500"
                  >
                    <v-card-text class="primary">
                      <p
                        style="color: #FFF"
                        v-html="`<i>${iten.description}</i>`"
                      ></p>
                    </v-card-text>
                  </v-card>
                </span>
              </v-tooltip>
              {{ iten.name }}
            </td>
            <td
              v-if="
                permisos.includes('nom_conditions_generals_cars:edit') ||
                  permisos.includes('nom_conditions_generals_cars:destroy') ||
                  permisos.includes('*')
              "
            >
              <v-tooltip
                v-if="permisos.includes('nom_conditions_generals_cars:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('nom_conditions_generals_cars:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiInformationOutline,
  mdiEraserVariant,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiInformationOutline,
        mdiEraserVariant,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      model: {
        proveedor_id: null,
        category_id: null,
        name: null,
      },
      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,

      itemsProveedorForm: [],
      searchProveedorForm: null,
    }
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
    searchProveedorForm(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedorForm(val.toLowerCase())
      }
    },
  },
  created() {
    this.getProveedores()
    this.profile()
    this.load()
    this.loadAll()
    this.getCategories()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(
          `nom_conditions_generals?for_car=1
            &page=${this.pagination.current}
            &per_page=${this.itemsPerPage}
            &perfil_slug=${localStorage.getItem('perfil')}
            &proveedor_id=${this.model.proveedor_id}
            &category_id=${this.model.category_id}
            &name=${this.model.name}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    loadAll() {
      this.axios
        .get('nom_conditions_generals?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    getCategories() {
      this.axios
        .get('nom_categories_conditions?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categories = res.data.data
          }
        })
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    filterProveedorForm(v) {
      this.itemsProveedorForm = []
      if (v === '') {
        this.itemsProveedorForm = []
      } else {
        this.itemsProveedorForm = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('conditions-generals-id')
      localStorage.removeItem('conditions-generals-show')
      this.$router.push({ name: 'conditions-generals-cars-update' })
    },
    editItem(item) {
      localStorage.setItem('conditions-generals-id', item.id)
      localStorage.removeItem('conditions-generals-show')
      this.$router.push({ name: 'conditions-generals-cars-update' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_conditions_generals/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },
    clearSearch() {
      this.model = {
        proveedor_id: null,
        category_id: null,
        name: null,
      }
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
